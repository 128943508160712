import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Head from './404.styles'

const NotFoundPage = () => (
  <Layout isWhiteHeader grayBackground container>
    <Head>
      <Head.container>
        <SEO title='404: Not found' />
        <Head.header1>404</Head.header1>
        <Head.header2>Page not found</Head.header2>
        <p>The page you requested could not be found.</p>
      </Head.container>
      <Head.section className='col-md-12'>
        <Button
          href='/'
          dataElementLocation={DataElementLocations.BODY}
          dataElementLabel={'404-sitebuilder'}
          dataElementId={'404-sitebuilder-button'}
          color='cta'
          variant='contained'
          className='signup-button button-xl'
          size='large'>
          Home
        </Button>
      </Head.section>
    </Head>
  </Layout>
)

export default NotFoundPage
